import { useMemo } from 'react';
import { Subject, asyncScheduler } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
export const useThrottledSendTyping = (charService, time) => useMemo(() => {
    const subject = new Subject();
    subject.pipe(throttleTime(time, asyncScheduler, {
        leading: true,
        trailing: true
    })).subscribe(params => {
        charService.sendTyping(params);
    });
    return params => subject.next(params);
}, [time]);
