import { ChatEventType } from '../enums';
export class MessageEvent {
    constructor(_ref) {
        let message = _ref.message, conversationId = _ref.conversationId;
        this.type = ChatEventType.Message;
        this.message = void 0;
        this.conversationId = void 0;
        this.message = message;
        this.conversationId = conversationId;
    }
}
