import { ChatEventType } from '../enums';
export class UserTypingEvent {
    constructor(_ref) {
        let userId = _ref.userId, conversationId = _ref.conversationId, content = _ref.content, isTyping = _ref.isTyping;
        this.type = ChatEventType.UserTyping;
        this.userId = void 0;
        this.conversationId = void 0;
        this.content = void 0;
        this.isTyping = void 0;
        this.userId = userId;
        this.conversationId = conversationId;
        this.content = content;
        this.isTyping = isTyping;
    }
}
