function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) {
    if ("string" == typeof r)
        return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
} }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++)
    n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) {
    var e, n, i, u, a = [], f = !0, o = !1;
    try {
        if (i = (t = t.call(r)).next, 0 === l) {
            if (Object(t) !== t)
                return;
            f = !1;
        }
        else
            for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0)
                ;
    }
    catch (r) {
        o = !0, n = r;
    }
    finally {
        try {
            if (!f && null != t.return && (u = t.return(), Object(u) !== u))
                return;
        }
        finally {
            if (o)
                throw n;
        }
    }
    return a;
} }
function _arrayWithHoles(r) { if (Array.isArray(r))
    return r; }
export class MessageGroup {
    constructor(_ref) {
        let id = _ref.id, senderId = _ref.senderId, direction = _ref.direction, sendTime = _ref.sendTime;
        this.id = void 0;
        this.senderId = void 0;
        this.direction = void 0;
        this.sendTime = void 0;
        this.messages = [];
        this.id = id;
        this.senderId = senderId;
        this.direction = direction;
        this.sendTime = sendTime;
    }
    addMessage(message) {
        this.messages.push(message);
    }
    addPreviousMessage(message) {
        const currentMessages = [...this.messages];
        this.messages = [message, ...currentMessages];
    }
    getMessage(id) {
        const idx = this.messages.findIndex(message => message.id === id);
        if (idx !== -1) {
            return [this.messages[idx], idx];
        }
        else {
            return [undefined, undefined];
        }
    }
    /**
     * Replace the given message in the message collection
     * @param message
     */
    updateMessage(message) {
        const _this$getMessage = this.getMessage(message.id), _this$getMessage2 = _slicedToArray(_this$getMessage, 2), foundMessage = _this$getMessage2[0], idx = _this$getMessage2[1];
        if (foundMessage) {
            this.replaceMessage(foundMessage, idx);
        }
    }
    /**
     * Replace the given message in the message collection
     * @param message
     */
    removeMessage(id) {
        const _this$getMessage3 = this.getMessage(id), _this$getMessage4 = _slicedToArray(_this$getMessage3, 1), foundMessage = _this$getMessage4[0];
        if (foundMessage) {
            this.messages = this.messages.filter(m => m.id === foundMessage.id);
        }
    }
    /**
     * Remove message by status
     * @param status
     */
    removeAllMessageByStatus(status) {
        this.messages = this.messages.filter(m => m.status !== status);
    }
    /**
     * Replace the message at the specified index in the message collection.
     * Returns true if the message exists at the specified position.
     * Returns false if the given index is out of bound.
     * @param message
     * @param index
     */
    replaceMessage(message, index) {
        if (this.messages.length <= index) {
            return false;
        }
        this.messages = this.messages.slice(0, index).concat(message).concat(this.messages.slice(index + 1));
        return true;
    }
    /**
     * Check if message exists in messages collection
     * @param messageId
     */
    messageExists(messageId) {
        return this.messages.findIndex(m => m.id === messageId) !== -1;
    }
}
