export class ChatMessage {
    constructor(_ref) {
        let id = _ref.id, status = _ref.status, contentType = _ref.contentType, senderId = _ref.senderId, direction = _ref.direction, content = _ref.content, _ref$createdTime = _ref.createdTime, createdTime = _ref$createdTime === void 0 ? new Date() : _ref$createdTime, updatedTime = _ref.updatedTime, _ref$position = _ref.position, position = _ref$position === void 0 ? "normal" : _ref$position, _ref$seen = _ref.seen, seen = _ref$seen === void 0 ? 0 : _ref$seen, messagedId = _ref.messagedId, groupContentDate = _ref.groupContentDate, file = _ref.file;
        this.id = void 0;
        this.status = void 0;
        this.contentType = void 0;
        this.senderId = void 0;
        this.direction = void 0;
        this.content = void 0;
        this.createdTime = void 0;
        this.updatedTime = void 0;
        this.position = void 0;
        this.seen = void 0;
        this.messagedId = void 0;
        this.groupContentDate = void 0;
        this.file = void 0;
        this.id = id;
        this.status = status;
        this.contentType = contentType;
        this.senderId = senderId;
        this.direction = direction;
        this.content = content;
        this.createdTime = createdTime;
        this.updatedTime = updatedTime;
        this.position = position;
        this.seen = seen;
        this.messagedId = messagedId;
        this.groupContentDate = groupContentDate;
        this.file = file;
    }
}
