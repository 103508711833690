function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) {
    if ("string" == typeof r)
        return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
} }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++)
    n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) {
    var e, n, i, u, a = [], f = !0, o = !1;
    try {
        if (i = (t = t.call(r)).next, 0 === l) {
            if (Object(t) !== t)
                return;
            f = !1;
        }
        else
            for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0)
                ;
    }
    catch (r) {
        o = !0, n = r;
    }
    finally {
        try {
            if (!f && null != t.return && (u = t.return(), Object(u) !== u))
                return;
        }
        finally {
            if (o)
                throw n;
        }
    }
    return a;
} }
function _arrayWithHoles(r) { if (Array.isArray(r))
    return r; }
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { useCallback, useRef } from 'react';
export const useDebounceTyping = (duration, updateState, storage) => {
    const debouncers = useRef(new Map());
    const createDebouncer = useCallback(() => {
        const subject = new Subject();
        subject.pipe(debounceTime(duration)).subscribe(_ref => {
            let conversationId = _ref.conversationId, userId = _ref.userId;
            // Stop subject
            subject.complete();
            // Remove debouncer from collection
            const deb = debouncers.current;
            const conversationItem = deb.get(conversationId);
            if (conversationItem) {
                conversationItem.delete(userId);
                // Cleanup. Remove conversation if it doesn't contain any users
                if (conversationItem.size === 0) {
                    deb.delete(conversationId);
                }
            }
            // Remove typing user from conversation
            const _storage$getConversat = storage.getConversation(conversationId), _storage$getConversat2 = _slicedToArray(_storage$getConversat, 1), conversation = _storage$getConversat2[0];
            if (conversation) {
                conversation.removeTypingUser(userId);
                updateState();
            }
        });
        return subject;
    }, [debouncers, duration, updateState, storage]);
    return (conversationId, userId) => {
        const deb = debouncers.current;
        const conversationItem = deb.get(conversationId);
        if (conversationItem) {
            const userItem = conversationItem.get(userId);
            if (userItem) {
                userItem.next({
                    conversationId,
                    userId
                });
            }
            else {
                const subject = createDebouncer();
                conversationItem.set(userId, subject);
            }
        }
        else {
            const subject = createDebouncer();
            const newEntry = new Map();
            newEntry.set(userId, subject);
            deb.set(conversationId, newEntry);
            subject.next({
                conversationId,
                userId
            });
        }
    };
};
