// import { v4 as uuidv4 } from "uuid";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { nanoid } from "nanoid";
import { BasicStorage, Presence, User, UserStatus } from "../useChat";
import { ExampleChatService } from "../useChat/examples";
export const messageIdGenerator = _message => nanoid();
export const groupIdGenerator = () => nanoid();
const currentUserStorage = new BasicStorage({
    groupIdGenerator,
    messageIdGenerator
});
// Create serviceFactory
const serviceFactory = (storage, updateState) => {
    return new ExampleChatService(storage, updateState);
};
const currentUserModel = {
    name: "Admin",
    avatar: "https://use-chat.examples.chatscope.io/static/media/akane.b135c3e37eddcf5ea48e6204e807594f.svg"
};
const currentUser = new User({
    id: currentUserModel.name,
    presence: new Presence({
        status: UserStatus.Available,
        description: ""
    }),
    firstName: "",
    lastName: "",
    username: currentUserModel.name,
    email: "",
    avatar: currentUserModel.avatar,
    bio: ""
});
const markAsReadMessage = (accountId, conversationId) => __awaiter(void 0, void 0, void 0, function* () {
    //  Logic here
});
export { currentUser, currentUserStorage, serviceFactory, markAsReadMessage };
