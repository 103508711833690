import { useCallback, useRef } from "react";
const DEFAULT_DELAY = 500;
const useDebounceFn = function () {
    let delay = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DEFAULT_DELAY;
    const timeoutIdRef = useRef(null);
    const delayRef = useRef(delay || DEFAULT_DELAY);
    const debounce = useCallback((debounceFn, delayFn) => {
        if (delayRef.current !== delayFn && typeof delayFn === "number") {
            delayRef.current = delayFn;
        }
        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
        }
        const timeoutId = setTimeout(debounceFn, delayRef.current);
        timeoutIdRef.current = timeoutId;
    }, [delayRef.current]);
    function cancel() {
        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
        }
    }
    return {
        debounce,
        cancel
    };
};
export default useDebounceFn;
