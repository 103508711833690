import consumer from "./consumer";
export const tabId = Math.random().toString(36).substring(7);
const chatChannel = consumer.subscriptions.create({
    channel: "ChatsChannel",
    tab_id: tabId
}, {
    connected() {
        // Called when the subscription is ready for use on the server
        console.log("subscribed");
    },
    disconnected() {
        // Called when the subscription has been terminated by the server
    },
    received(data) {
        // Called when there's incoming data on the websocket for this channel
        console.log("Received message:", data);
    },
    speak(data) {
        // Called when there's incoming data on the websocket for this channel
        console.log("Received message:", data);
    }
});
export default chatChannel;
