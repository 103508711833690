function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) {
    if ("string" == typeof r)
        return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
} }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++)
    n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) {
    var e, n, i, u, a = [], f = !0, o = !1;
    try {
        if (i = (t = t.call(r)).next, 0 === l) {
            if (Object(t) !== t)
                return;
            f = !1;
        }
        else
            for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0)
                ;
    }
    catch (r) {
        o = !0, n = r;
    }
    finally {
        try {
            if (!f && null != t.return && (u = t.return(), Object(u) !== u))
                return;
        }
        finally {
            if (o)
                throw n;
        }
    }
    return a;
} }
function _arrayWithHoles(r) { if (Array.isArray(r))
    return r; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t)
    return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != typeof i)
        return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
} return ("string" === r ? String : Number)(t); }
import { Conversation } from '.';
import { MessageGroup } from './MessageGroup';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class BasicStorage {
    get groupIdGenerator() {
        return this._groupIdGenerator;
    }
    get messageIdGenerator() {
        return this._messageIdGenerator;
    }
    /**
     * Constructor
     * @param messageIdGenerator
     * @param groupIdGenerator
     */
    constructor(_ref) {
        let groupIdGenerator = _ref.groupIdGenerator, messageIdGenerator = _ref.messageIdGenerator;
        this._groupIdGenerator = void 0;
        this._messageIdGenerator = void 0;
        this.currentUser = void 0;
        // TODO: Users by Id
        this.users = [];
        // TODO: Conversations By Id (Dedicated conversations collection)
        this.conversations = [];
        this.activeConversationId = void 0;
        this.messages = {};
        this.currentMessage = '';
        this.unreadMessages = undefined;
        this._groupIdGenerator = groupIdGenerator;
        this._messageIdGenerator = messageIdGenerator;
    }
    getMessageWithId(message, generateId) {
        if (generateId) {
            if (!this.messageIdGenerator) {
                throw 'Id generator is not defined';
            }
            else {
                return _objectSpread(_objectSpread({}, message), {}, {
                    id: this.messageIdGenerator(message)
                });
            }
        }
        else {
            return message;
        }
    }
    /**
     * Check if user exists in users collection
     * @param userId
     */
    userExists(userId) {
        return this.users.findIndex(u => u.id === userId) !== -1;
    }
    /**
     * Sets current (logged in) user object
     * @param user
     */
    setCurrentUser(user) {
        this.currentUser = user;
    }
    /**
     * Add user to collection of users.
     * User will be added only when item with its id not exists in the collection.
     * @param user
     */
    addUser(user) {
        const notExists = !this.userExists(user.id);
        if (notExists) {
            this.users = this.users.concat(user);
        }
        return notExists;
    }
    /**
     * Remove user from users collection.
     * If the participant existed and has been removed, it returns true, otherwise it returns false
     * @param userId
     */
    removeUser(userId) {
        const idx = this.users.findIndex(u => u.id === userId);
        if (idx !== -1) {
            this.users = this.users.slice(0, idx).concat(this.users.slice(idx + 1));
            return true;
        }
        return false;
    }
    /**
     * Get user by id
     * @param userId
     * @return [User, number]|[undefined,undefined]
     */
    getUser(userId) {
        const idx = this.users.findIndex(u => u.id === userId);
        if (idx !== -1) {
            return [this.users[idx], idx];
        }
        return [undefined, undefined];
    }
    /**
     * Update user by id
     * @param userId
     */
    updateUser(userId, user) {
        const idx = this.users.findIndex(u => u.id === userId);
        if (idx !== -1) {
            this.users[idx] = user;
            return this.users[idx];
        }
        return undefined;
    }
    /**
     * Checks if conversation exists
     * @param conversationId
     */
    conversationExists(conversationId) {
        return this.conversations.findIndex(c => c.id === conversationId) !== -1;
    }
    /**
     * Get conversation
     * @param conversationId
     * @return [Conversation, number]|[undefined, undefined]
     */
    getConversation(conversationId) {
        const idx = this.conversations.findIndex(c => c.id === conversationId);
        if (idx !== -1) {
            return [this.conversations[idx], idx];
        }
        return [undefined, undefined];
    }
    /**
     * Add conversation to collection of conversations.
     * Conversation will be added only when item with its id not exists in the collection.
     * @param conversation
     */
    addConversation(conversation) {
        const notExists = !this.conversationExists(conversation.id);
        if (notExists) {
            this.conversations = this.conversations.concat(conversation);
        }
        return notExists;
    }
    /**
     * Set unread messages for conversation
     * @param conversationId
     * @param count
     */
    setUnread(conversationId, count) {
        const _this$getConversation = this.getConversation(conversationId), _this$getConversation2 = _slicedToArray(_this$getConversation, 1), conversation = _this$getConversation2[0];
        if (conversation) {
            conversation.unreadCounter = count;
        }
    }
    /**
     * Set unread messages for conversation
     * @param conversationId
     * @param description
     */
    setConversationDescription(conversationId, description) {
        const _this$getConversation3 = this.getConversation(conversationId), _this$getConversation4 = _slicedToArray(_this$getConversation3, 1), conversation = _this$getConversation4[0];
        if (conversation && description) {
            conversation.description = description;
        }
    }
    /**
     * Remove conversation from conversations collection.
     * If the conversation existed and has been removed, it returns true, otherwise it returns false
     * @param conversationId
     * @param removeMessages
     */
    removeConversation(conversationId) {
        let removeMessages = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
        const idx = this.conversations.findIndex(c => c.id === conversationId);
        if (idx !== -1) {
            this.conversations = this.conversations.slice(0, idx).concat(this.conversations.slice(idx + 1));
            if (removeMessages) {
                delete this.messages[conversationId];
            }
            return true;
        }
        return false;
    }
    /**
     * Remove conversation from conversations collection.
     * If the conversation existed and has been removed, it returns true, otherwise it returns false
     * @param conversationId
     * @param removeMessages
     */
    removeAllConversation() {
        this.conversations = [];
        return true;
    }
    /**
     * Replace the conversation in the collection with the new one specified in the parameter
     * @param conversation
     */
    updateConversation(conversation) {
        const _this$getConversation5 = this.getConversation(conversation.id), _this$getConversation6 = _slicedToArray(_this$getConversation5, 2), con = _this$getConversation6[0], idx = _this$getConversation6[1];
        if (con) {
            this.replaceConversation(conversation, idx);
        }
    }
    replaceConversation(conversation, idx) {
        this.conversations = this.conversations.slice(0, idx).concat(new Conversation({
            id: conversation.id,
            participants: conversation.participants,
            typingUsers: conversation.typingUsers,
            unreadCounter: conversation.unreadCounter,
            draft: conversation.draft,
            description: conversation.description,
            readonly: conversation.readonly,
            data: conversation.data
        })).concat(this.conversations.slice(idx + 1));
    }
    replaceUser(user, idx) {
        this.users = this.users.slice(0, idx).concat(user).concat(this.users.slice(idx + 1));
    }
    /**
     * Add participant to the conversation only if not already added and conversation exists.
     * Returns true if participant has been added, otherwise returns false.
     * @param conversationId
     * @param participant
     * @return boolean
     */
    addParticipant(conversationId, participant) {
        const _this$getConversation7 = this.getConversation(conversationId), _this$getConversation8 = _slicedToArray(_this$getConversation7, 2), conversation = _this$getConversation8[0], idx = _this$getConversation8[1];
        if (conversation) {
            if (conversation.addParticipant(participant)) {
                this.replaceConversation(conversation, idx);
            }
        }
        return false;
    }
    /**
     * Remove participant from conversation.
     * If the participant existed and has been removed, it returns true, otherwise it returns false
     * @param conversationId
     * @param participantId
     */
    removeParticipant(conversationId, participantId) {
        const _this$getConversation9 = this.getConversation(conversationId), _this$getConversation10 = _slicedToArray(_this$getConversation9, 2), conversation = _this$getConversation10[0], idx = _this$getConversation10[1];
        if (conversation) {
            conversation.removeParticipant(participantId);
            this.replaceConversation(conversation, idx);
            return true;
        }
        return false;
    }
    addMessage(message, conversationId) {
        let generateId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
        if (conversationId in this.messages) {
            const groups = this.messages[conversationId];
            const lastGroup = groups[groups.length - 1];
            if (lastGroup.senderId === message.senderId) {
                // Add message to group
                const newMessage = this.getMessageWithId(message, generateId);
                lastGroup.addMessage(newMessage);
                return newMessage;
            }
        }
        const group = new MessageGroup({
            id: this.groupIdGenerator(),
            senderId: message.senderId,
            direction: message.direction
        });
        const newMessage = this.getMessageWithId(message, generateId);
        group.addMessage(newMessage);
        this.messages[conversationId] = conversationId in this.messages ? this.messages[conversationId].concat(group) : [group];
        return newMessage;
    }
    addPreviousMessage(message, conversationId) {
        let generateId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
        if (conversationId in this.messages) {
            const groups = this.messages[conversationId];
            const firstGroup = groups[0];
            if (firstGroup.senderId === message.senderId) {
                // Add message to group
                const newMessage = this.getMessageWithId(message, generateId);
                firstGroup.addPreviousMessage(newMessage);
                return newMessage;
            }
        }
        const group = new MessageGroup({
            id: this.groupIdGenerator(),
            senderId: message.senderId,
            direction: message.direction
        });
        const newMessage = this.getMessageWithId(message, generateId);
        group.addPreviousMessage(newMessage);
        this.messages[conversationId] = conversationId in this.messages ? [group, ...this.messages[conversationId]] : [group];
        return newMessage;
    }
    // TODO: Refactoring - it's not very optimal :)
    /**
     * Update message
     * @param message
     */
    updateMessage(message) {
        for (const conversationId in this.messages) {
            const groups = this.messages[conversationId];
            const l = groups.length;
            for (let i = 0; i < l; i++) {
                const group = groups[i];
                const _group$getMessage = group.getMessage(message.id), _group$getMessage2 = _slicedToArray(_group$getMessage, 2), currentMessage = _group$getMessage2[0], idx = _group$getMessage2[1];
                if (currentMessage) {
                    group.replaceMessage(message, idx);
                }
            }
        }
    }
    removeAllMessageByStatus(status) {
        for (const conversationId in this.messages) {
            const groups = this.messages[conversationId];
            const l = groups.length;
            for (let i = 0; i < l; i++) {
                const group = groups[i];
                group.removeAllMessageByStatus(status);
            }
        }
    }
    /**
     * Set user presence
     * @param userId
     * @param presence
     */
    setPresence(userId, presence) {
        const _this$getUser = this.getUser(userId), _this$getUser2 = _slicedToArray(_this$getUser, 2), user = _this$getUser2[0], idx = _this$getUser2[1];
        if (user) {
            user.presence = presence;
            this.replaceUser(user, idx);
        }
    }
    /**
     * Set draft of message in current conversation
     * @param {String} draft
     */
    setDraft(draft) {
        if (this.activeConversationId) {
            const _this$getConversation11 = this.getConversation(this.activeConversationId), _this$getConversation12 = _slicedToArray(_this$getConversation11, 2), activeConversation = _this$getConversation12[0], idx = _this$getConversation12[1];
            if (activeConversation) {
                activeConversation.draft = draft;
                this.replaceConversation(activeConversation, idx);
            }
        }
    }
    clearState() { }
    getState() {
        return {
            currentUser: this.currentUser,
            users: this.users,
            conversations: this.conversations,
            // TODO: Implement sth like collection referencing by id (map with guaranteed order like in immutablejs) because searching every time in the array does not make sense
            activeConversation: this.getActiveConversation(),
            currentMessages: this.getCurrentMessage(),
            messages: this.messages,
            currentMessage: this.currentMessage,
            unreadMessages: this.unreadMessages
        };
    }
    resetState() {
        this.currentUser = undefined;
        this.users = [];
        this.conversations = [];
        this.activeConversationId = undefined;
        this.messages = {};
    }
    /**
     * Set active conversation and reset unread counter of this conversation if second parameter is set.
     * Why active conversation is kept here in storage?
     * Because it's easy to persist whole storage and recreate from persistent state.
     * @param conversationId
     * @param resetUnreadCounter
     */
    setActiveConversation(conversationId) {
        let resetUnreadCounter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
        this.activeConversationId = conversationId;
        if (resetUnreadCounter && conversationId) {
            const _this$getConversation13 = this.getConversation(conversationId), _this$getConversation14 = _slicedToArray(_this$getConversation13, 2), conversation = _this$getConversation14[0], idx = _this$getConversation14[1];
            if (conversation) {
                conversation.unreadCounter = 0;
                this.replaceConversation(conversation, idx);
            }
        }
    }
    /**
     * Get active conversation.
     */
    getActiveConversation() {
        if (this.activeConversationId) {
            return this.conversations.find(c => c.id === this.activeConversationId);
        }
        return undefined;
    }
    /**
     * Get current message in active conversation.
     */
    getCurrentMessage() {
        if (this.activeConversationId && this.activeConversationId in this.messages) {
            return this.messages[this.activeConversationId];
        }
        return [];
    }
    /**
     * Get current message in a conversation.
     */
    getCurrentMessageByConversation(conversationId) {
        if (conversationId in this.messages) {
            return this.messages[conversationId];
        }
        return [];
    }
    /**
     * Set current  message input value
     * @param message
     */
    setCurrentMessage(message) {
        this.currentMessage = message;
    }
    /**
     * Remove all the messages from the conversation
     * @param conversationId
     */
    removeMessagesFromConversation(conversationId) {
        delete this.messages[conversationId];
    }
    setUnreadMessages(unReadMessages) {
        this.unreadMessages = unReadMessages;
    }
}
