export class TypingUser {
    constructor(_ref) {
        let userId = _ref.userId, content = _ref.content, isTyping = _ref.isTyping;
        this.userId = void 0;
        this.content = void 0;
        this.isTyping = void 0;
        this.userId = userId;
        this.content = content;
        this.isTyping = isTyping;
    }
}
