import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import "dayjs/locale/ja";
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(isSameOrAfter);
dayjs.extend(weekOfYear);
export function convertToJapanTime(date) {
    let format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "YYYY/MM/DD HH:mm";
    if (!date)
        return "";
    return dayjs(date).locale("ja").format(format);
}
export function convertConversationToJapanTime(date) {
    if (date) {
        const parsedDate = dayjs(date);
        if (parsedDate.isValid()) {
            if (parsedDate.isToday()) {
                return parsedDate.format("HH:mm"); // Time for today
            }
            else {
                return parsedDate.format("MM/DD"); // Date in 'MM/dd' format for older dates
            }
        }
    }
    return "";
}
export function convertToString(value) {
    if (value) {
        return value.toString();
    }
    return "";
}
