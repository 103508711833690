import { ConversationRole } from '.';
export class Participant {
    constructor(_ref) {
        let id = _ref.id, _ref$role = _ref.role, role = _ref$role === void 0 ? new ConversationRole([]) : _ref$role;
        this.id = void 0;
        this.role = void 0;
        this.id = id;
        this.role = role;
    }
}
