import { Presence } from './Presence';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class User {
    constructor(_ref) {
        let id = _ref.id, _ref$presence = _ref.presence, presence = _ref$presence === void 0 ? new Presence({}) : _ref$presence, _ref$firstName = _ref.firstName, firstName = _ref$firstName === void 0 ? '' : _ref$firstName, _ref$lastName = _ref.lastName, lastName = _ref$lastName === void 0 ? '' : _ref$lastName, _ref$username = _ref.username, username = _ref$username === void 0 ? '' : _ref$username, _ref$nickname = _ref.nickname, nickname = _ref$nickname === void 0 ? '' : _ref$nickname, _ref$email = _ref.email, email = _ref$email === void 0 ? '' : _ref$email, _ref$avatar = _ref.avatar, avatar = _ref$avatar === void 0 ? '' : _ref$avatar, _ref$bio = _ref.bio, bio = _ref$bio === void 0 ? '' : _ref$bio, data = _ref.data;
        this.id = void 0;
        this.presence = new Presence({});
        this.firstName = void 0;
        this.lastName = void 0;
        this.username = void 0;
        this.nickname = void 0;
        this.email = void 0;
        this.avatar = void 0;
        this.bio = void 0;
        this.data = void 0;
        this.id = id;
        this.presence = presence;
        this.firstName = firstName;
        this.lastName = lastName;
        this.username = username;
        this.nickname = nickname;
        this.email = email;
        this.avatar = avatar;
        this.bio = bio;
        this.data = data;
    }
}
