import { UserStatus } from './enums/UserStatus';
export class Presence {
    constructor(_ref) {
        let _ref$status = _ref.status, status = _ref$status === void 0 ? UserStatus.Unknown : _ref$status, _ref$description = _ref.description, description = _ref$description === void 0 ? '' : _ref$description;
        this.status = UserStatus.Unknown;
        this.description = '';
        this.status = status;
        this.description = description;
    }
}
