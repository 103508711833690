var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t)
    return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != typeof i)
        return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
} return ("string" === r ? String : Number)(t); }
import dayjs from "dayjs";
import { ChatMessage, Conversation, ConversationRole, MessageContentType, MessageDirection, MessageStatus, Participant, TypingUsersList } from "../useChat";
export function createConversation(id, userId, data) {
    var _a;
    return new Conversation({
        id,
        participants: [new Participant({
                id: userId,
                role: new ConversationRole([])
            })],
        unreadCounter: (data === null || data === void 0 ? void 0 : data.unread_messages_count) || 0,
        typingUsers: new TypingUsersList({
            items: []
        }),
        draft: "",
        description: (_a = data === null || data === void 0 ? void 0 : data.last_message.content) !== null && _a !== void 0 ? _a : "",
        data: data
    });
}
const getCSRFToken = () => {
    var _a;
    return (_a = document.querySelector('meta[name="csrf-token"]')) === null || _a === void 0 ? void 0 : _a.getAttribute("content");
};
export const getConversations = (page, filterTab, freeSearch, gender) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    try {
        const response = yield fetch(`/chats/conversations?page=${page}&free_search=${freeSearch}&filter_type=${filterTab}&gender=${gender}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": (_a = getCSRFToken()) !== null && _a !== void 0 ? _a : ""
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = yield response.json();
        return data;
    }
    catch (error) {
        console.error("API Error:", error);
        return null;
    }
});
export const getMessagesInConversation = function (chatId) {
    var arguments_1 = arguments;
    return __awaiter(this, void 0, void 0, function* () {
        var _a;
        let page = arguments_1.length > 1 && arguments_1[1] !== undefined ? arguments_1[1] : 1;
        try {
            const response = yield fetch(`/chats/${chatId}/messages?page=${page}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-Token": (_a = getCSRFToken()) !== null && _a !== void 0 ? _a : ""
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = yield response.json();
            return data;
        }
        catch (error) {
            console.error("API Error:", error);
            return null;
        }
    });
};
export function checkIdExistsInMessages(currentMessages, messageId) {
    if (!messageId) {
        return true;
    }
    return currentMessages.some(group => group.messages.some(message => { var _a; return ((_a = message === null || message === void 0 ? void 0 : message.messagedId) === null || _a === void 0 ? void 0 : _a.toString()) === (messageId === null || messageId === void 0 ? void 0 : messageId.toString()); }));
}
export const parseLineMessageToChatMessage = message => {
    const baseMessage = {
        id: message.id,
        content: message.content,
        contentType: message.type === "image" ? MessageContentType.Image : MessageContentType.TextPlain,
        createdTime: new Date(message.created_at),
        updatedTime: new Date(message.updated_at),
        is_read: message.is_read
    };
    if (message.sender === 0) {
        return new ChatMessage(_objectSpread(_objectSpread({}, baseMessage), {}, {
            senderId: "Admin",
            // Replace with sender id
            status: MessageStatus.Sent,
            direction: MessageDirection.Outgoing,
            messagedId: message.id
        }));
    }
    return new ChatMessage(_objectSpread(_objectSpread({}, baseMessage), {}, {
        senderId: message.user_id,
        status: MessageStatus.Seen,
        direction: MessageDirection.Incoming,
        messagedId: message.id
    }));
};
export const replaceUrlFromMessageContent = text => {
    if (text) {
        const pattern = /(?<!<img[^>]*src=")https?:\/\/[^\s<>]+/g;
        return text.replace(pattern, url => `<a href=${url} target='_blank' rel="noopener noreferrer">${url}</a>`);
    }
    return "";
};
export const sortConversationsByDesc = conversations => {
    return conversations.sort((a, b) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        const aPinnedAt = ((_a = a.data) === null || _a === void 0 ? void 0 : _a.pinned_conversation_at) ? dayjs((_b = a.data) === null || _b === void 0 ? void 0 : _b.pinned_conversation_at).valueOf() : 0;
        const bPinnedAt = ((_c = b.data) === null || _c === void 0 ? void 0 : _c.pinned_conversation_at) ? dayjs((_d = b.data) === null || _d === void 0 ? void 0 : _d.pinned_conversation_at).valueOf() : 0;
        if (bPinnedAt !== aPinnedAt) {
            return bPinnedAt - aPinnedAt;
        }
        const aLastMessageTime = ((_f = (_e = a.data) === null || _e === void 0 ? void 0 : _e.last_message) === null || _f === void 0 ? void 0 : _f.time) ? dayjs((_h = (_g = a.data) === null || _g === void 0 ? void 0 : _g.last_message) === null || _h === void 0 ? void 0 : _h.time).valueOf() : 0;
        const bLastMessageTime = ((_k = (_j = b.data) === null || _j === void 0 ? void 0 : _j.last_message) === null || _k === void 0 ? void 0 : _k.time) ? dayjs((_m = (_l = b.data) === null || _l === void 0 ? void 0 : _l.last_message) === null || _m === void 0 ? void 0 : _m.time).valueOf() : 0;
        return bLastMessageTime - aLastMessageTime;
    });
};
export const sortConversations = conversations => {
    return sortConversationsByDesc(conversations);
};
