function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) {
    if ("string" == typeof r)
        return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
} }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++)
    n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) {
    var e, n, i, u, a = [], f = !0, o = !1;
    try {
        if (i = (t = t.call(r)).next, 0 === l) {
            if (Object(t) !== t)
                return;
            f = !1;
        }
        else
            for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0)
                ;
    }
    catch (r) {
        o = !0, n = r;
    }
    finally {
        try {
            if (!f && null != t.return && (u = t.return(), Object(u) !== u))
                return;
        }
        finally {
            if (o)
                throw n;
        }
    }
    return a;
} }
function _arrayWithHoles(r) { if (Array.isArray(r))
    return r; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t)
    return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != typeof i)
        return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
} return ("string" === r ? String : Number)(t); }
import { TypingUsersList } from './TypingUsersList';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class Conversation {
    constructor(_ref) {
        let id = _ref.id, _ref$participants = _ref.participants, participants = _ref$participants === void 0 ? [] : _ref$participants, _ref$unreadCounter = _ref.unreadCounter, unreadCounter = _ref$unreadCounter === void 0 ? 0 : _ref$unreadCounter, _ref$typingUsers = _ref.typingUsers, typingUsers = _ref$typingUsers === void 0 ? new TypingUsersList({
            items: []
        }) : _ref$typingUsers, _ref$draft = _ref.draft, draft = _ref$draft === void 0 ? '' : _ref$draft, _ref$description = _ref.description, description = _ref$description === void 0 ? '' : _ref$description, _ref$readonly = _ref.readonly, readonly = _ref$readonly === void 0 ? false : _ref$readonly, data = _ref.data;
        this.id = void 0;
        this.unreadCounter = 0;
        this.participants = void 0;
        this.typingUsers = void 0;
        this.description = '';
        this.draft = '';
        this.readonly = false;
        this.data = void 0;
        this.id = id;
        this.unreadCounter = unreadCounter;
        this.participants = participants;
        this.typingUsers = typingUsers;
        this.draft = draft;
        this.description = description;
        this.readonly = readonly;
        this.data = data;
    }
    /**
     * Checks if participant exists
     * @param participantId
     * @returns boolean
     */
    participantExists(participantId) {
        return this.participants.findIndex(p => p.id === participantId) !== -1;
    }
    /**
     * Get participant and its index
     * @param participantId
     * @returns [Participant,number]|[undefined,undefined]
     */
    getParticipant(participantId) {
        const idx = this.participants.findIndex(p => p.id === participantId);
        if (idx !== -1) {
            return [this.participants[idx], idx];
        }
        return [undefined, undefined];
    }
    /**
     * Add participant to the collection only if not already added.
     * Returns true if participant has been added, otherwise returns false.
     * Returns
     * @param participant
     * @returns boolean
     */
    addParticipant(participant) {
        if (!this.participantExists(participant.id)) {
            this.participants = this.participants.concat(_objectSpread({}, participant));
            return true;
        }
        return false;
    }
    /**
     * Removes participant.
     * If the participant existed and has been removed, it returns true, otherwise it returns false
     * @param participantId
     * @returns boolean
     */
    removeParticipant(participantId) {
        const _this$getParticipant = this.getParticipant(participantId), _this$getParticipant2 = _slicedToArray(_this$getParticipant, 2), idx = _this$getParticipant2[1];
        if (idx) {
            this.participants = this.participants.slice(0, idx).concat(this.participants.slice(idx + 1));
            return true;
        }
        return false;
    }
    /**
     * Ads typing user to the typing users collection.
     * If user with this id already exists it will be replaced
     * @param typingUser
     */
    addTypingUser(typingUser) {
        this.typingUsers.addUser(typingUser);
    }
    /**
     * Remove user with specified id from the collection of typing users
     * @param userId
     */
    removeTypingUser(userId) {
        this.typingUsers.removeUser(userId);
    }
    /**
     * Set unread counter
     * @param unreadCounter
     */
    setUnreadCounter(unreadCounter) {
        this.unreadCounter = unreadCounter;
    }
    /**
     * Get description
     */
    getDescription() {
        return this.description;
    }
    /**
     * Set description
     * @param description
     */
    setDescription(description) {
        this.description = description;
    }
    /**
     * Get Draft
     */
    getDraft() {
        return this.draft;
    }
    /**
     * Set Draft
     * @param draft
     */
    setDraft(draft) {
        this.draft = draft;
    }
    /**
     * Get latest message
     */
    getData() {
        return this.data;
    }
    /**
     * Set latest message
     * @param message
     */
    setData(data) {
        this.data = data;
    }
}
